import axios from 'axios';

import config from '../config';
import getQueryStringFromObject from '../utils/queryFormatter';

const server = axios.create({
    baseURL: config.baseUrl
});

export const listBars = async (payload) => {
    try {
        let url = getQueryStringFromObject(payload);
        const response = await server.get(`/bars?${url}`, {
            headers: {
              token: localStorage.getItem('token')
            }
          });
          return response.data;
          
    } catch (error) {
        return error.response.data;
    }
}

export const addBar = async (payload) => {
    try {
        const response = await server.post(`/bars`,
            payload,
            {headers:{token:localStorage?.getItem('token')}}
        );
        return response.data;
    } catch (error) {
        return error;
    }
};

export const editBar = async (payload) => {
    try {
        const response = await server.put(`/bars/${payload?._id}`,payload,{
            headers:{token:localStorage?.getItem('token')}});
        return response.data;
    } catch (error) {
        return error;
    }
};

export const getBar = async (bar_id) => {
    try {
        const response = await server.get(`/bars/${bar_id}`,{
            headers:{token:localStorage?.getItem('token')}});
        return response.data;
    } catch (error) {
        return error;
    }
};
export const getGroupsOfBars = async (payload,bar_id) => {
    try {
        let url = getQueryStringFromObject(payload);
        const response = await server.get(`/bars/${bar_id}/groups?${url}`,{
            headers:{token:localStorage?.getItem('token')}});
        return response.data;
    } catch (error) {
        return error;
    }
};



