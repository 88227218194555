import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

class TinyMCEEditor extends React.Component {

  render() {
    return (
        <Editor
            tinymceScriptSrc={'/tinymce/tinymce.min.js'}
            initialValue={this.props.content}
            value={this.props.content}
            init={{
                disable_nodechange: true,
                height: 600,
                menubar: false,
                // plugins: [
                //     'advlist autolink lists link image charmap print preview anchor',
                //     'searchreplace visualblocks code fullscreen',
                //     'insertdatetime media table paste code help wordcount'
                // ],
                plugins: [],
                // toolbar:
                //     'undo redo | formatselect | bold italic backcolor | \
                //     alignleft aligncenter alignright alignjustify | \
                //     bullist numlist outdent indent | removeformat | help | print',
                toolbar: 'print',
                // init_instance_callback: function (editor) {
                //     editor.on('Change', function () {
                //         const content = editor.getContent();
                //         this.props.onContentChange(content, editor);
                //     }.bind(this));
                // }.bind(this)
            }}
        />
    );
  }
}

export default TinyMCEEditor;
