import { Helmet } from "react-helmet-async";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
// @mui
import {
  Stack,
  Container,
  Typography,
  Autocomplete,
  TextField,
  Button,
  Switch,
  Grid,
  IconButton,
  FormControlLabel,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Pagination,
} from "@mui/material";
import i18n from "../i18n";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getGroupsOfBars, listBars } from "../services/BarService";
import {
  addUser,
  getUser,
  getUserTransactions,
  updateUser,
} from "../services/UsersService";
import config from "../config";
import styled from "styled-components";
import { fCurrency } from "../utils/formatNumber";
import moment from "moment";
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------
const StatusSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));
class AddEditUser extends React.Component {
  constructor(props) {
    super(props);
    this.role = localStorage.getItem(config.roleKey);
    this.params = this.props.params;
    this.navigate = this.props.navigate;
    this.temp = [
      { label: "Super Admin", value: "superadmin" },
      { label: "Admin", value: "admin" },
      { label: "Bar Owner", value: "barowner" },
      { label: "Cashier", value: "cashier" },
      { label: "Server", value: "server" },
      { label: "User", value: "user" },
    ];
    this.columns = [
      { field: "id", headerName: i18n.t("order_id"), flex: 1 },
      { field: "payrexxId", headerName: i18n.t("payrexx_id"), flex: 1 },
      {
        field: "payrexxTransactionId",
        headerName: i18n.t("payrexx_transaction_id"),
        flex: 1,
      },
      {
        field: "payrexxReferenceId",
        headerName: i18n.t("payrexx_reference_id"),
        flex: 1,
      },
      { field: "payrexxUuid", headerName: i18n.t("payrexx_Uuid"), flex: 1 },
      { field: "payrexxStatus", headerName: i18n.t("payrexx_Status"), flex: 1 },
      { field: "paymentStatus", headerName: i18n.t("payment_Status"), flex: 1 },
      // { field: 'orderId', headerName: 'Order ID', flex: 1},
      { field: "created_at", headerName: i18n.t("date"), flex: 1 },
      {
        field: "total",
        headerName: i18n.t("total"),
        type: "number",
        flex: 1,
        valueFormatter: (params) => {
          return fCurrency(
            params.value,
            i18n.t("OPTIONS.i18n_number_format"),
            config.currencyCode,
            false
          );
        },
      },
    ];
    this.Swal = require("sweetalert2");
    this.state = {
      formData: {
        username: "",
        password: "",
        role: { label: "", value: "" },
        bars: [],
        status: "active",
        groups: [],
        defaultPaymentType: { label: "", value: null },
      },
      userTransactionHistory: [],
      userTransactionHistoryCount: 0,
      editMode: false,
      roleData: [],
      hideComplete: false,
      hideBar: false,
      barData: [],
      groupsOptions: [],
      errors: {},
      page: 1,
      skip: 0,
      limit: 25,
    };
    this.paymentTypes = config?.paymentTypes;
    this.saveFormData = this.saveFormData.bind(this);
    this.tableLimitOptions = config?.tableLimitOptions;
  }
  componentDidMount() {
    if (this.props.params.user_id) {
      this.setState({
        editMode: true,
      });
      this.getUserData();
      this.getUserTransactionsData();
    } else {
      this.setState({
        editMode: false,
      });
    }
    this.getBarList();
    this.filterDropDown();
  }
  filterDropDown() {
    let tempData;
    if (this.role === "admin") {
      tempData = [this.temp[2], this.temp[3]];
    } else if (this.role === "barowner") {
      tempData = [this.temp[3], this.temp[4]];
    } else {
      tempData = this.temp;
    }
    this.setState({
      roleData: [...tempData],
    });
  }

  async saveFormData() {
    if (!(await this.isFormValid())) {
      return;
    }
    let tempBarId = this.state.formData?.bars?.map((el) => {
      return el?._id;
    });
    let payload = {
      ...this.state.formData,
      bars: tempBarId,
      role: this.state.formData?.role?.value,
    };
    delete payload?.__v;
    delete payload?.assets;
    if (
      this.state.formData?.role?.value === "superadmin" ||
      this.state.formData?.role?.value === "user"
    ) {
      delete payload?.bars;
    }
    if (this.state.formData?.role?.value === "admin") {
      delete payload?.bars;
    }
    /**
     * Default payment type for server and cashier
     */
    if (
      this.state.formData?.role?.value === "server" ||
      this.state.formData?.role?.value === "cashier"
    ) {
      payload.defaultPaymentType =
        this.state.formData?.defaultPaymentType?.value || null;
    } else {
      payload.defaultPaymentType = null;
    }
    if (this.state.formData?.role?.value === "server") {
      /**sending only group ids -
       * configured only for server
       */
      let tempGroupIds = [];
      this.state.formData?.groups?.forEach((groupData) => {
        tempGroupIds.push(groupData?._id);
      });
      payload.groups = tempGroupIds;
    } else {
      payload.groups = [];
    }

    if (this.state.editMode) {
      this.updateUserData(payload);
    } else {
      this.addUserData(payload);
    }
  }

  getRole(data) {
    return this.temp?.find((item) => {
      return item?.value === data.user.role;
    });
  }
  async getUserData() {
    this.Swal.showLoading();
    let response = await getUser(this.props.params?.user_id);
    if (response?.response?.data?.error) {
      this.Swal.close();
      this.Swal.fire(response?.response?.data?.error);
      return;
    }
    if (response) {
      this.Swal.close();
      let barDataMap = response?.user?.bars?.map((el) => {
        return { name: el?.name, _id: el?._id };
      });
      /**
       * for server there will be only one bar mapped
       * hence index of 0
       */
      let paymentTypeData = { label: "", value: null };
      if (response?.user?.defaultPaymentType) {
        if(!response?.user?.hideDefaultPaymentType){
          paymentTypeData = this.paymentTypes?.filter((option) => {
            return option?.value === response?.user?.defaultPaymentType;
          })?.[0];
        }else{
          paymentTypeData=this.paymentTypes?.[0]
        }
       
      }
      let groupsData = [];
      if (response?.user?.groups) {
        let tempOptions = await this.getGroupsBar(
          response?.user?.bars?.[0]?._id
        );
        groupsData = tempOptions?.filter((option) => {
          return response?.user?.groups.includes(option?._id);
        });
      }

      let tempData = {
        ...response?.user,
        username: response?.user?.username,
        role: {
          label: this.getRole(response)?.label,
          value: this.getRole(response)?.value,
        },
        bars: barDataMap,
        status: response?.user?.status,
      };
      if (
        response?.user?.role === "server" ||
        response?.user?.role === "cashier"
      )
        tempData.defaultPaymentType = paymentTypeData;
      if (response?.user?.role === "server") tempData.groups = groupsData;
      this.setState({
        formData: { ...tempData },
      });
      await this.state.formData;
      setTimeout(() => {
        this.hideDropDowns(tempData);
      }, 100);
    }
  }
  async getUserTransactionsData() {
    this.Swal.showLoading();
    const { limit, skip } = this.state;
    let queryParams = {
      limit,
      skip,
    };
    let response = await getUserTransactions(
      queryParams,
      this.props.params?.user_id
    );
    if (response?.response?.data?.error) {
      this.Swal.close();
      this.Swal.fire(response?.response?.data?.error);
      return;
    }
    if (response) {
      this.Swal.close();
      response?.userTransactions.forEach((onlineTransaction) => {
        // id should be last 6 characters of _id and capitalised
        onlineTransaction.id = onlineTransaction._id
          .substring(onlineTransaction._id.length - 6)
          .toUpperCase();
        onlineTransaction.created_at = moment(
          onlineTransaction.updatedAt
        ).format(i18n.t("OPTIONS.date_time_format"));
        onlineTransaction.total = onlineTransaction?.grandTotal?.toFixed(2);
      });
      let page_count = Math.ceil(response.count / limit);
      this.setState({
        userTransactionHistory: [...response?.userTransactions],
        userTransactionHistoryCount: page_count,
      });
    }
  }

  async addUserData(payload) {
    this.Swal.showLoading();
    let data = await addUser(payload);
    if (data?.response?.data?.error) {
      this.Swal.close();
      this.Swal.fire(data?.response?.data?.error);
      return;
    }
    if (data) {
      this.Swal.close();
      this.Swal.fire({
        toast: true,
        icon: "success",
        text: "User Created Successfully.",
        position: "center",
        showConfirmButton: false,
        timer: 2000,
      });
      this.goBack();
    }
  }
  async updateUserData(payload) {
    this.Swal.showLoading();
    let id = this.props.params.user_id;
    let response = await updateUser(payload, id);
    if (response.error) {
      this.Swal.close();
      this.Swal.fire(response.error);
      return;
    }
    if (response) {
      this.Swal.close();
      this.Swal.fire({
        toast: true,
        icon: "success",
        text: "User details edited Successfully.",
        position: "center",
        showConfirmButton: false,
        timer: 2000,
      });
      this.goBack();
    }
  }

  async validateForm(field) {
    let error = { ...this.state.errors };
    if (field == null || field === "username") {
      if (
        !this.state.formData?.username ||
        this.state.formData?.username === ""
      ) {
        error.username = i18n.t("this_field_is_required");
      } else {
        delete error.username;
      }
    }
    if (!this.state.editMode && (field == null || field === "password")) {
      if (
        !this.state.formData?.password ||
        this.state.formData?.password === ""
      ) {
        error.password = i18n.t("this_field_is_required");
      } else {
        delete error.password;
      }
    }
    this.setState({ errors: error });

    return error;
  }
  async isFormValid() {
    let error = await this.validateForm();
    if (Object?.keys(error)?.length > 0) {
      return false;
    } else {
      return true;
    }
  }
  async getBarList() {
    let payload = {
      minimal: true,
    };
    let response = await listBars(payload);
    if (response) {
      let temp = response?.bars?.map((el) => {
        return { name: el?.name, _id: el?._id };
      });
      this.setState({
        barData: temp,
      });
    }
    if (response.error) {
      alert(response.error);
      return;
    }
  }
  async getGroupsBar(bar_id) {
    let payload = {
      minimal: true,
    };
    let response = await getGroupsOfBars(payload, bar_id);
    if (response) {
      let temp = response?.groups?.map((el) => {
        return { name: el?.name, _id: el?._id };
      });
      await this.setState({
        groupsOptions: temp,
      });
      return temp;
    }
    if (response.error) {
      alert(response.error);
      return;
    }
  }
  goBack() {
    this.navigate(-1);
  }

  async hideDropDowns(data) {
    await this.state.formData;
    if (this.state.formData?.role?.value === "") {
      this.setState({
        hideComplete: false,
      });
    }
    if (
      this.state.formData?.role?.value === "superadmin" ||
      this.state.formData?.role?.value === "user"
    ) {
      this.setState({
        hideComplete: false,
      });
    }
    if (this.state.formData?.role?.value === "admin") {
      this.setState({
        hideComplete: true,
        hideBar: false,
      });
    }
    if (
      this.state.formData?.role?.value === "barowner" ||
      this.state.formData?.role?.value === "server" ||
      this.state.formData?.role?.value === "cashier"
    ) {
      this.setState({
        hideComplete: true,
        hideBar: true,
      });
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>
            {this.state.editMode
              ? i18n.t("edit_user")
              : i18n.t("add_new_user") | config.APPLICATION_NAME}
          </title>
        </Helmet>

        <Container
          style={{
            background: "white",
            maxWidth: "100%",
            minHeight:'100%',
            borderRadius: "10px",
            padding: "20px",
          }}
        >
          <Stack direction="row" alignItems="center" mb={5}>
            <IconButton>
              <ArrowBackIcon
                onClick={() => {
                  this.goBack();
                }}
                style={{ cursor: "pointer" }}
              />
            </IconButton>
            <Typography variant="h4" className="mb-0" gutterBottom>
              {this.state.editMode
                ? i18n.t("edit_user")
                : i18n.t("add_new_user")}
            </Typography>
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                type="text"
                id="username"
                label={i18n.t("username")}
                name="username"
                autoComplete="username"
                onChange={async (e) => {
                  await this.setState({
                    formData: {
                      ...this.state.formData,
                      username: e.target.value,
                    },
                  });
                  await this.validateForm("username");
                }}
                error={this.state.errors?.username ? true : false}
                helperText={this.state.errors?.username}
                value={this.state.formData?.username}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                type="text"
                id="password"
                label={i18n.t("password")}
                name="password"
                autoComplete="password"
                onChange={async (e) => {
                  await this.setState({
                    formData: {
                      ...this.state.formData,
                      password: e.target.value,
                    },
                  });
                  await this.validateForm("password");
                }}
                error={this.state.errors?.password ? true : false}
                helperText={this.state.errors?.password}
                value={this.state.formData?.password}
              />
            </Grid>
          </Grid>
          <Grid container className="mb-3 mt-2" spacing={2}>
            <Grid item xs={12} md={4}>
              <Autocomplete
                disablePortal
                options={
                  !this.state.roleData
                    ? [{ label: "Loading...", id: 0 }]
                    : this.state.roleData
                }
                onChange={(option, value) => {
                  this.setState({
                    formData: {
                      ...this.state.formData,
                      role: { label: value?.label, value: value?.value },
                      bars: [],
                      defaultPaymentType: { label: "", value: null },
                    },
                  });
                  this.hideDropDowns(this.state?.formData);
                }}
                value={this.state.formData?.role?.label}
                renderInput={(params) => <TextField {...params} label="Role" />}
                style={{ background: "white" }}
              />
            </Grid>
            {this.state.hideComplete && (
              <>
                {this.state.hideBar && (
                  <>
                    {this.state?.formData?.role?.value === "server" ||
                    this.state?.formData?.role?.value === "cashier" ? (
                      <>
                        <Grid item xs={12} md={4}>
                          <Autocomplete
                            style={{ background: "white" }}
                            single
                            disablePortal
                            id="combo-box-demo"
                            autoHighlight={true}
                            isOptionEqualToValue={(option, value) =>
                              option.name === value.name
                            }
                            includeInputInList={true}
                            value={
                              this.state.formData?.bars?.[0]
                                ? {
                                    name:
                                      this.state.formData?.bars?.[0]?.name ||
                                      "",
                                    _id: this.state.formData?.bars?.[0]?._id,
                                  }
                                : { name: "", _id: "" }
                            }
                            options={
                              !this.state.barData
                                ? [{ name: "Loading...", id: 0 }]
                                : this.state.barData
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                formData: {
                                  ...this.state.formData,
                                  bars: [
                                    { name: value?.name, _id: value?._id },
                                  ],
                                },
                              });
                              this.getGroupsBar(value?._id);
                            }}
                            getOptionLabel={(option) => option?.name ? `${option.name}` : ""}
                            renderInput={(params) => (
                              <TextField {...params} label="Bar" />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Autocomplete
                            style={{ background: "white" }}
                            single
                            disablePortal
                            id="combo-box-demo"
                            disabled={this.state?.formData?.hideDefaultPaymentType}
                            autoHighlight={true}
                            isOptionEqualToValue={(option, value) =>
                              option.label === value.label
                            }
                            includeInputInList={true}
                            value={
                              this.state.formData?.defaultPaymentType || ""
                            }
                            options={this.paymentTypes}
                            onChange={async (option, value) => {
                              await this.setState({
                                formData: {
                                  ...this.state.formData,
                                  defaultPaymentType: {
                                    label: value?.label || "",
                                    value: value?.value,
                                  },
                                },
                              });
                            }}
                            getOptionLabel={(option) => option?.label ? `${option.label}` : ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Default Payment Type"
                              />
                            )}
                          />
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={12} md={4}>
                        <Autocomplete
                          multiple
                          limitTags={1}
                          style={{ background: "white" }}
                          id="multiple-limit-tags"
                          value={this.state.formData?.bars || []}
                          options={
                            !this.state.barData
                              ? [{ name: "Loading...", id: 0 }]
                              : this.state.barData
                          }
                          filterSelectedOptions={true}
                          onChange={async (option, value) => {
                            await this.setState({
                              formData: {
                                ...this.state.formData,
                                bars: [...value],
                              },
                            });
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option?._id === value?._id
                          }
                          getOptionLabel={(option) => option?.name ? `${option.name}` : ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Bar"
                              placeholder="Bar"
                            />
                          )}
                        />
                      </Grid>
                    )}
                    {this.state?.formData?.role?.value === "server" &&
                      this.state.formData?.bars?.[0]?.name && (
                        <Grid item xs={12} md={4}>
                          <Autocomplete
                            multiple
                            limitTags={2}
                            style={{ background: "white" }}
                            id="multiple-limit-tags"
                            value={this.state.formData?.groups || []}
                            options={
                              !this.state.groupsOptions
                                ? [{ name: "Loading...", id: 0 }]
                                : this.state.groupsOptions
                            }
                            filterSelectedOptions={true}
                            onChange={async (option, value) => {
                              await this.setState({
                                formData: {
                                  ...this.state.formData,
                                  groups: [...value],
                                },
                              });
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option?._id === value?._id
                            }
                            getOptionLabel={(option) => option?.name ? `${option.name}` : ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Groups"
                                placeholder="Groups"
                              />
                            )}
                          />
                        </Grid>
                      )}
                  </>
                )}
              </>
            )}
          </Grid>
          <Grid container>
          <Grid item xs={1}>
            <FormControlLabel
              control={
                <StatusSwitch
                  onChange={async (e) => {
                    await this.setState({
                      formData: {
                        ...this.state.formData,
                        status: e.target.checked ? "active" : "inactive",
                      },
                    });
                  }}
                  checked={
                    this.state.formData?.status === "active" ? true : false
                  }
                  className="mt-1"
                  style={{ cursor: "pointer" }}
                />
              }
              label={i18n.t("status")}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              control={
                <StatusSwitch
                  onChange={async (e) => {
                    await this.setState({
                      formData: {
                        ...this.state.formData,
                        hideDefaultPaymentType: e.target.checked,
                        defaultPaymentType:e.target.checked ? this.paymentTypes?.[0] : {label:'',value:''}
                      },
                    });
                  }}
                  checked={
                    this.state.formData?.hideDefaultPaymentType
                  }
                  className="mt-1"
                  style={{ cursor: "pointer" }}
                />
              }
              label={i18n.t("hideDefaultPaymentType")}
            />
          </Grid>
          </Grid>
          {this.state?.formData?.role?.value === 'user' && this.state?.editMode && <Grid item xs={12} style={{ margin: "15px 0" }}>
            <TableContainer component={Paper}>
              <Table style={{ margin: "15px 0" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {this.columns?.map((header) => {
                      return <TableCell>{header?.headerName}</TableCell>;
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state?.userTransactionHistory?.length > 0 ? (
                    this.state?.userTransactionHistory?.map((row) => (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        {this.columns?.map((header) => {
                          return (
                            <TableCell component="th" scope="row">
                              {row[header["field"]]}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      fullWidth
                    >
                      <TableCell colSpan={12}>
                        <Typography
                          variant="h7"
                          className="mb-0 d-flex"
                          gutterBottom
                          justifyContent="center"
                        >
                          {i18n.t("no_data_found")}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <Grid
                container
                spacing={2}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid></Grid>
                <Grid item>
                  <Pagination
                    variant="outlined"
                    shape="rounded"
                    count={this.state.userTransactionHistoryCount}
                    page={this.state.page}
                    onChange={async (e, value) => {
                      await this.setState({
                        page: value,
                        skip: (value - 1) * this.state.limit,
                      });
                      this.getUserTransactionsData();
                    }}
                    style={{ margin: "10px", marginTop: "24px" }}
                  />
                </Grid>

                <Grid item style={{ width: "10%" }}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="demo-simple-select-label">Limit</InputLabel>
                    <Select
                      value={this.state.limit || ""}
                      label="Limit"
                      size="small"
                      onChange={async (e) => {
                        await this.setState({
                          ...this.state,
                          limit: e?.target?.value,
                        });
                        this.getUserTransactionsData();
                      }}
                    >
                      {this.tableLimitOptions?.map((limit) => {
                        return (
                          <MenuItem value={limit?.value}>
                            {limit?.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </TableContainer>
          </Grid>}
          <Grid container>
            <Grid
              item
              xs={6}
              className="d-flex"
              direction="row"
              justifyContent="flex-end"
            >
              <Button
                onClick={this.saveFormData}
                variant="contained"
                style={{ marginRight: "25px", padding: "5px 30px" }}
              >
                {i18n.t("save")}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                onClick={() => {
                  this.goBack();
                }}
                variant="outlined"
                style={{ border: "1px solid grey" }}
              >
                {i18n.t("cancel")}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <AddEditUser {...props} navigate={navigate} params={params} />;
}
