import React from "react";
import { Grid, Button, Typography, IconButton, Divider } from "@mui/material";
import i18n from "../../i18n";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import DragNDropContainer from "./DragAndDropContainer";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AddIcon from "@mui/icons-material/Add";
import GppGoodIcon from "@mui/icons-material/GppGood";
import GppBadIcon from "@mui/icons-material/GppBad";
class ConsumptionsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropPosition: "",
    };
    this.styles = {
      tableHeaderStyles: {
        textAlign: "start",
        background: "#f0f0f0",
        padding: "8px",
        marginLeft: "15px",
        paddingLeft: "16px",
      },
      tableBodyStyles: {
        textAlign: "start",
        padding: "10px",
        cursor: "pointer",
      },
      highlight: {
        border: "1px solid #0085f2",
        padding: "0px 10px 0px 10px",
        borderRadius: "10px",
      },
    };
    this.onDropEventHandler = this.onDropEventHandler.bind(this);
    this.highlightDrop = this.highlightDrop.bind(this);
  }
  async componentDidMount() {}
  async onDropEventHandler(draggedItemData, destPath) {
    this.props.onDragEnd(draggedItemData, destPath);
  }
  async highlightDrop(path, position) {
    this.setState({
      dropPosition: path,
    });
  }
  render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <Grid container spacing={2} className="mb-4 mt-2">
          <Grid item xs={8} style={{ textAlign: "start" }}>
            <h5
              style={{
                fontWeight: "bold",
                fontSize: "1.20rem",
                marginBottom: "0",
              }}
            >
              {i18n.t("consumptions")}
            </h5>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "end" }}>
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => {
                this.props.onAction(
                  "updateIndex",
                  this.props.categoryIndex,
                  this.props.groupIndex
                );
              }}
            >
              {i18n.t("add_consumptions")}
            </Button>
          </Grid>
        </Grid>
        <Grid className="d-flex" style={this.styles.tableHeaderStyles}>
          <Grid item xs={2}>
            {i18n.t("name")}
          </Grid>
          <Grid
            item
            className="d-flex"
            direction="row"
            justifyContent="center"
            xs={1}
          >
            {i18n.t("price")}
          </Grid>
          <Grid
            item
            className="d-flex"
            direction="row"
            justifyContent="center"
            xs={1}
          >
            {i18n.t("VAT")}
          </Grid>
          <Grid item xs={2}>
            {i18n.t("quickOrder")}
          </Grid>
          <Grid item xs={2}>
            {i18n.t("status")}
          </Grid>
          <Grid item xs={2}>
            {i18n.t("hidden")}
          </Grid>
          <Grid
            item
            className="d-flex"
            direction="row"
            justifyContent="flex-end"
            xs={2}
          >
            {i18n.t("actions")}
          </Grid>
        </Grid>
        <DragNDropContainer
          key="empty-div"
          onDrop={this.onDropEventHandler}
          path="0"
          containerType="droppable"
          isEmpty
          canDrag={true}
          dragType=""
          dropType={["consumption"]}
          padding="5px 0"
        >
          {this.props?.data?.children?.map((row, foodIndex) => (
            <>
              <DragNDropContainer
                path={row?.path}
                dragItem={{ element: row }}
                highlightDrop={this.highlightDrop}
                onDrop={this.onDropEventHandler}
                canDrag={true}
                dragType={"consumption"}
                dropType={["consumption"]}
              >
                <div
                  style={
                    this.state.dropPosition === row?.path
                      ? this.styles?.highlight
                      : {}
                  }
                >
                  <Grid
                    container
                    className="d-flex"
                    style={this.styles.tableBodyStyles}
                  >
                    <Grid item xs={2}>
                      <DragIndicatorIcon style={{ cursor: "pointer" }} />
                      {row["name"]}
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      className="d-flex"
                      direction="row"
                      style={{ paddingLeft: "30px" }}
                      justifyContent="center"
                    >
                      {row["price"]}
                    </Grid>
                    <Grid
                      item
                      xs={1}
                      className="d-flex"
                      direction="row"
                      justifyContent="center"
                      style={{ paddingLeft: "20px" }}
                    >
                      {row["VAT"]}
                    </Grid>
                    <Grid item xs={2} style={{ paddingLeft: "40px" }}>
                      <span
                        style={{
                          backgroundColor:
                            row["quickOrder"] === true
                              ? "green"
                              : row.status === false
                              ? "orange"
                              : "red",
                          padding: 5,
                          textAlign: "center",
                          color: "white",
                          textAlign: "center",
                          borderRadius: 5,
                          fontSize: 12,
                          fontWeight: "bold",
                        }}
                      >
                        {row["quickOrder"] === true
                          ? i18n.t("yes")
                          : i18n.t("No")}
                      </span>
                    </Grid>
                    <Grid item xs={2} style={{ paddingLeft: "15px" }}>
                      <span
                        style={{
                          backgroundColor:
                            row.status === "active"
                              ? "green"
                              : row.status === "inactive"
                              ? "orange"
                              : "red",
                          padding: 5,
                          color: "white",
                          borderRadius: 5,
                          fontSize: 12,
                          fontWeight: "bold",
                        }}
                      >
                        {i18n.t(row?.status)}
                      </span>
                    </Grid>
                    <Grid item xs={2} style={{ paddingLeft: "15px" }}>
                      <span>
                        {row?.hidden ? <GppGoodIcon /> : <GppBadIcon />}
                      </span>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      className="d-flex"
                      direction="row"
                      justifyContent="flex-end"
                    >
                      <Typography
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.props.onAction(
                            "showEditConsumptionModal",
                            this.props.categoryIndex,
                            this.props.groupIndex,
                            foodIndex,
                            row
                          );
                        }}
                      >
                        <IconButton>
                          <EditIcon />
                        </IconButton>
                      </Typography>
                      <Typography
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          this.props.onAction(
                            "delete",
                            this.props.categoryIndex,
                            this.props.groupIndex,
                            foodIndex
                          );
                        }}
                      >
                        <IconButton color="error">
                          <DeleteOutlineIcon />
                        </IconButton>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </div>
              </DragNDropContainer>
            </>
          ))}
        </DragNDropContainer>
      </DndProvider>
    );
  }
}
export default ConsumptionsTable;
