import { Helmet } from "react-helmet-async";
import React, { Component } from "react";
import Stack from '@mui/material/Stack';
// @mui
import {
  Card,
  Table,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  IconButton,
  TableContainer,
  TableHead,
  Grid,
  Pagination,
  TextField,
  InputAdornment,
  Typography,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
// components
import Iconify from "../components/iconify";
import Swal from "sweetalert2";
import { getUsers } from "../services/UsersService";
import { Search } from "@mui/icons-material";
import i18n from "../i18n";
import config from "../config";
import EditIcon from "@mui/icons-material/Edit";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------


class UserList extends Component {
  constructor(props) {
    super(props);

    this.tableLimitOptions = config?.tableLimitOptions
    this.state = {
      rows: [],
      page: 1,
      limit: 10,
      skip: 0,
      pageCount: 0,
      search: "",
      isNotFound: false,
      open: null,
      rowData: null,
    };
  }

  componentDidMount() {
    this.getUserList();
  }

  getUserList = async (show_loading = true) => {

    if (show_loading) {
      Swal.showLoading();
    }

    const { page, limit, skip, search } = this.state;
    let payload = {
      limit,
      skip,
    }
    if (search !== '') {
      payload['searchvalue'] = search
    }
    let response = await getUsers(payload);
    if (response.error) {
      if (show_loading) {
        Swal.close();
      }
      alert(response.error)
      return;
    }
    if (response) {
      let page_count = Math.ceil(response.count / limit);
      this.setState({
        rows: response.users,
        pageCount: page_count,
      });
      if (show_loading) {
        Swal.close();
      }
    }
  };

  handleOpenMenu = (event, rowData) => {
    this.setState({
      open: event.currentTarget,
      rowData,
    });
  };

  handleCloseMenu = () => {
    this.setState({
      open: null,
      rowData: null,
    });
  };

  render() {
    const {
      rows,
      page,
      limit,
      skip,
      pageCount,
      search,
      isNotFound,
      open,
      rowData,
    } = this.state;

    return (
      <>
        <Helmet>
          <title>{i18n.t("users") | config.APPLICATION_NAME}</title>
        </Helmet>
        <Container maxWidth='100%'>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" className="m-0" gutterBottom>
              {i18n.t("users")}
            </Typography>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={() => {
                this.props.navigate("/users/create");
              }}
            >
              {i18n.t("add_new_user")}
            </Button>
          </Stack>

          <Card style={{ padding: '24px', borderRadius: 'unset' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={5} xl={4}>
                <TextField
                  label={i18n.t("search")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  style={{
                    width: "100%",
                    marginBottom: "16px",
                  }}
                  onChange={async (e) => {
                    await this.setState({ page: 1, skip: 0, search: e.target.value });
                    this.getUserList(false);
                  }}
                />
              </Grid>
            </Grid>

            <TableContainer>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>{i18n.t("full_name")}</TableCell>
                    <TableCell>{i18n.t("username")}</TableCell>
                    <TableCell>{i18n.t("role")}</TableCell>
                    <TableCell>{i18n.t("status")}</TableCell>
                    <TableCell>{i18n.t("email")}</TableCell>
                    <TableCell>{i18n.t("actions")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.username}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">{row.fullname}</TableCell>
                      <TableCell component="th" scope="row">{row.username}</TableCell>
                      <TableCell>{row.role}</TableCell>
                      <TableCell>
                        <span
                          style={{
                            backgroundColor: (row.status === "active" ? "green" : row.status === "inactive" ? "orange" : "red"),
                            padding: 5,
                            color: 'white',
                            borderRadius: 5,
                            fontSize: 12,
                            fontWeight: 'bold'
                          }}
                        >
                          {i18n.t(row.status)}
                        </span>
                      </TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>
                        <span>
                          <Tooltip
                            title={i18n.t("edit")}
                            placement="top-start"
                          >
                            <IconButton>
                              <EditIcon
                                color="primary"
                                onClick={() => {
                                  this.props.navigate(`/users/${row?._id}`);
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <Grid container spacing={2}  style={{ width: '100%' , display:'flex' , justifyContent:"space-between" , alignItems:'center'}}>
              <Grid item></Grid>
              <Grid item>
                <Pagination variant="outlined" shape="rounded" size="large"
                  count={pageCount}
                  page={page}
                  onChange={async (e, value) => {
                    await this.setState({
                      page: value,
                      skip: (value - 1) * limit
                    });
                    this.getUserList();
                  }}
                  style={{ margin: '10px' }}
                />
              </Grid>
              <Grid item style={{ width: "10%" }}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">Limit</InputLabel>
                  <Select
                    value={this.state.limit}
                    label="Limit"
                    onChange={async (e) => {
                      await this.setState({
                        ...this.state,
                        limit: e?.target?.value
                      })
                      this.getUserList();
                    }}
                  >
                    {this.tableLimitOptions?.map((limit) => {
                      return (
                        <MenuItem value={limit?.value}>{limit?.label}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

          </Card>
        </Container>
      </>
    );
  }
}

export default UserList;