// component
import SvgColor from '../../../components/svg-color';
import RecentActorsIcon from '@mui/icons-material/RecentActors';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

let navConfig = [{
  title: 'dashboard',
  path: '/dashboard',
  icon: icon('ic_analytics'),
},{
  title: 'clients',
  path: '/clients',
  icon: <RecentActorsIcon />,
}, {
  title: 'bars',
  path: '/bars',
  icon: icon('bar'),
}, {
  title: 'users',
  path: '/users',
  icon: icon('ic_user'),
}, {
  title: 'reports',
  path: '/reports',
  icon: icon('ic_analytics'),
}];

export default navConfig;
