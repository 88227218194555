import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Header from './header';
import Nav from './nav';

import {
  useNavigate
} from "react-router-dom";
import config from '../../config';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  overflow: 'hidden',
  height:'100vh'
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem(config.tokenKey);
    if (token && token !== "" && token !== "undefined") {
      
    } else {
      localStorage.clear();
      navigate('/login');
    }
  }, [navigate]);


  return (
    <div style={{height:'calc(100% - 57px)'}}>
      <Header onOpenNav={() => setOpen(true)} navigate={navigate} />
      <StyledRoot>
        <Nav openNav={open} onCloseNav={() => setOpen(false)} />

        <Main style={{paddingTop:'20px'}}>
          <Outlet />
        </Main>
      </StyledRoot>
    </div>
  );
}
