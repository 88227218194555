import React from 'react';
import TinyMCEEditor from '../editor/TinyMCEEditor';
import moment from 'moment';
import { fCurrency } from '../../utils/formatNumber';
import config from '../../config';
import i18n from '../../i18n';

class Type1Report extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            content: ''
        }
    }

    componentDidMount() {
        this.createContent();
    }

    // handleContentChange = (content, editor) => {
    //     this.setState({ content });
    // }

    createContent() {
        
        let reportDate = this.props.reportDate;
        let reportMode = this.props.reportMode;
        let report = this.props.report;
        let bar = report.bar;
        let details = report.details;

        if (reportMode === 'monthly') {
            reportDate = moment(reportDate).format(i18n.t('OPTIONS.month_format_text'));
        } else if (reportMode === 'yearly') {
            reportDate = moment(reportDate).format(i18n.t('OPTIONS.year_format'));
        } else {
            reportDate = moment(reportDate).format(i18n.t('OPTIONS.date_format'));
        }

        let aggregate = {
            total: 0,
            numberOfItems: 0,
            numberOfTickets: 0
        };
        details.forEach(detail => {
            aggregate.total += detail.total;
            aggregate.numberOfItems += detail.numberOfItems;
            aggregate.numberOfTickets += detail.numberOfTickets;
        });
        
        let content = `
            <h3 style="text-align: center">${bar.name}</h3>

            <h5 style="text-align: center">${i18n.t('statistics_by_group')}</h5>

            <table align="right" cellpadding="10" cellspacing="10" style="margin-top: 5px; margin-bottom: 20px; border: 1px solid white">
                <tr>
                    <td style="border: 1px solid white">${i18n.t('report_generated_on')}</td>
                    <td style="border: 1px solid white" align="right">${(moment()).format(i18n.t('OPTIONS.date_time_format'))}</td>
                </tr>
                <tr>
                    <td style="border: 1px solid white">${i18n.t('report_type')}</td>
                    <td style="border: 1px solid white" align="right">${i18n.t(reportMode)}</td>
                </tr>
                <tr>
                    <td style="border: 1px solid white">${i18n.t('report_for')}</td>
                    <td style="border: 1px solid white" align="right">${reportDate}</td>
                </tr>
            </table>
        `

        content += `
            <table width="100%" border="1.0">
                <tr>
                    <th>${i18n.t('group')}</th>
                    <th>${i18n.t('total')}</th>
                    <th>${i18n.t('number_of_items')}</th>
                    <th>${i18n.t('number_of_tickets')}</th>
                    <th>${i18n.t('%_total')}</th>
                </tr>
        `

        details.forEach(detail => {
            content += `
                <tr>
                    <td>${(detail.group && detail.group != 'others') ? detail.group : i18n.t('others')}</td>
                    <td align="right">${fCurrency(detail.total, i18n.t('OPTIONS.i18n_number_format'), config.currencyCode, false)}</td>
                    <td align="center">${detail.numberOfItems}</td>
                    <td align="center">${detail.numberOfTickets}</td>
                    <td align="right">${(detail.total / aggregate.total * 100).toFixed(2)} %</td>
                </tr>
            `
        });


        content += `
                <tr>
                    <th>${i18n.t('total')}</th>
                    <th align="right">${fCurrency(aggregate.total, i18n.t('OPTIONS.i18n_number_format'), config.currencyCode, false)}</th>
                    <th align="center">${aggregate.numberOfItems}</th>
                    <th align="center">${aggregate.numberOfTickets}</th>
                    <th align="right">100.00 %</th>
                </tr>
            </table>
        `

        this.setState({
            content: content
        })
    }

    render() {
        return (
            <div>
                <TinyMCEEditor
                    content={this.state.content}
                    readonly={true}
                    // onContentChange={this.handleContentChange}
                />
            </div>
        );
    }
}

export default Type1Report;
