// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
import './App.css';
// Language Translations
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import { useEffect } from "react";
import i18n from './utils/i18n';

function App() {
  var lang = navigator.language || navigator.userLanguage;
  useEffect(()=>{
      i18n.changeLanguage(lang);
  },[lang])
  return (
    <I18nextProvider i18n={i18next}>
      <div className="App">
        <ThemeProvider>
          <Router />
        </ThemeProvider>
      </div>
    </I18nextProvider>
  );
}

export default App;
