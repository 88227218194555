import React from "react";
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Card, CardContent, CardHeader } from "@mui/material";
import i18n from "../../i18n";
import config from "../../config";
import { fCurrency } from "../../utils/formatNumber";

function CustomToolbar() {
    return (
      <GridToolbarContainer style={{justifyContent: 'space-between'}}>
        <GridToolbarQuickFilter style={{padding: 10}} />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
}

class ConsumptionsTable extends React.Component {
    
    constructor(props) {
        super(props);

        this.columns = [
            { field: 'name', headerName: i18n.t('name'), flex: 2},
            { field: 'price', headerName: i18n.t('price'), type: 'number', flex: 1,
                valueFormatter: (params) => {
                    return fCurrency(params.value, i18n.t('OPTIONS.i18n_number_format'), config.currencyCode, false);
                }
            },
            { field: 'quantity', headerName: i18n.t('quantity'), type: 'number', flex: 1},
            { field: 'total', headerName: i18n.t('total'), type: 'number', flex: 1,
                valueFormatter: (params) => {
                    return fCurrency(params.value, i18n.t('OPTIONS.i18n_number_format'), config.currencyCode, false);
                }
            }
        ];
          
        this.rows = [];


        this.state = {
            pageSize: 10
        }
    }

    render() {

        this.rows = this.props?.consumptions || [];

        return (
            <Card style={{borderRadius: 'unset'}}>
                <CardHeader title={i18n.t('consumptions')} />
                <CardContent>
                    <DataGrid
                        autoHeight
                        rows={this.rows}
                        columns={this.columns}
                        pageSize={this.state.pageSize}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        onPageSizeChange={(newPageSize) => this.setState({pageSize: newPageSize})}
                        components={{ Toolbar: CustomToolbar }}
                        componentsProps={{
                            toolbar: {
                            showQuickFilter: true,
                            quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                        disableSelectionOnClick
                    />
                </CardContent>
            </Card>
        );
    }
}

export default ConsumptionsTable;