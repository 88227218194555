import axios from 'axios';
import moment from 'moment';

import config from '../config';

const server = axios.create({
    baseURL: config.baseUrl
});

export const getMonthlyReport = async (month=null) => {
    try {
        let url = `/reports/month`;
        if (month) {
            url += `?month=${month}`;
        }
        const response = await server.get(url, {
            headers: { token: localStorage?.getItem('token') }
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export const getMonthlyDaywiseReport = async (month=null,barId) => {
    try {
        let url = `/reports/bar/${barId}/month/daywise`; 
        if (month) {
            url += `?month=${month}`;
        }
        const response = await server.get(url, {
            headers: { token: localStorage?.getItem('token') }
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}

export const putMonthlyDaywiseReportDayData = async (payload,barId) => {
    try {
        const response = await server.patch(`/reports/bar/${barId}/month/daywise`, payload, {
            headers: { token: localStorage?.getItem('token') }
        });
        return response.data;
    } catch (error) {
        return error.response.data;
    }
}
